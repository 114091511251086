<template>
	<div class="container mt-4">
		<h2 class="label-header">Audio Files</h2>
		<div class="card mb-4">
			<div class="card-body">
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="isLoading" style="width: 100%; heigth: 100px;">
					<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
				</div>
				<form class="row g-3"  @submit.prevent="onSubmit" novalidate v-if="!isLoading">
					<div class="col-6">
						<label class="form-label">Course name</label>
						<select class="form-select" v-model="vv.course_id.$model" @change="loadTopic">
							<option :value="initialValue">Select Course</option>
							<option v-for="(item, i) in courseList" :key="i" :value="item.course_id">{{ item.course_code }}</option>
						</select>
						<span class="error">{{ vv?.course_id?.$errors[0]?.$message }}</span>
					</div>
					<div class="col-6">
						<label class="form-label">Topic</label>
						<select class="form-select" v-model="vv.topic_id.$model">
							<option value="0">All Topics</option>
							<option v-for="(item, i) in topicList" :key="i" :value="item.topic_id">{{ item.topic_title }}</option>
						</select>
						<span class="error">{{ vv?.topic_id?.$errors[0]?.$message }}</span>
					</div>
					<div class="col-12">
						<button class="btn btn-primary">Fecth Lecture Note</button>
					</div>
				</form>
			</div>
		</div>
		<div class="card mb-4">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover table-striped table-bordered">
						<thead>
							<tr>
								<th>Title</th>
								<th>Audio File</th>
								<th width="350px" style="min-width: 350px;">Action</th>
							</tr>
						</thead>
						<tbody v-if="noteSections.length > 0">
							<tr v-for="(item, i) in noteSections" :key="i">
								<td>{{ item.section_title }}</td>
								<td>
									<audio v-if="item.section_audio.length > 0" controls preload="metadata" :src="setAudioLink(item.section_audio)"></audio>
								</td>
								<td>
									<div class="btn-group">
										<label class="custom-file-upload">
											<input type="file" @change="handleFileChange(item, i)" :id="`audio-input-${i}`" accept="audio/mp3"/>
											Change Audio
										</label>
										<button class="btn btn-primary btn-sm" style="margin-right: 10px;" @click="saveAduioFile(item)">Save Audio</button>
										<button class="btn btn-danger btn-sm" style="margin-right: 10px;" @click="deleteAudioFile(item)" v-if="item.section_audio.length > 0">Delete Audio</button>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="noteSections.length === 0">
							<tr>
								<td colspan="3">
									<div class="d-flex justify-content-center flex-column align-items-center">
										No Note have been uploaded yet
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="d-flex justify-content-center flex-column align-items-center card-loader" v-if="isLoading1">
				<span style="width: 50px; height: 50px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Processing Data
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { audioService } from '@/services/audio.services'
export default {
	name: 'NoteAudio',
	setup () {
		const fform = reactive({
			topic_id: null,
			course_id: null
		})
		const rules = computed(() => (
		{
			topic_id: { required },
			course_id: { required }
		}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			isLoading: true,
			isLoading1: false,
			initialValue: null,
			courseList: [],
			topicList: [],
			noteSections: []
		};
	},
	mounted(){
		this.loadCourse()
	},
	methods: {
		loadCourse () {
			audioService.loadCourse()
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.courseList = response.data.courseList
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
				})
		},
		loadTopic () {
			this.isLoading = true
			audioService.loadTopic(this.fform.course_id)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.topicList = response.data.topicList
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
				})
		},
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.isLoading1 = true
			audioService.loadNote(this.fform.course_id, this.fform.topic_id)
				.then((response) => {
					this.isLoading1 = false
					if(parseInt(response.data.message_code) === 200){
						this.noteSections = response.data.noteList
					}
					console.log(response.data)
				})
				.catch((e) => {
					this.isLoading1 = false
					console.log(e)
				})
		},
		setAudioLink (audio) {
			//console.log(audio)
			if(audio.length === 0){
				return audio
			}
			const audioFile = audio.indexOf('base64')
			if(audioFile !== -1) {
				return audio
			}else{
				return `${audioService.apiUrl}/note-audio/${audio}`
			}
		},
		async handleFileChange (itemObj, index){
			const audioElem = document.getElementById(`audio-input-${index}`)
			if(!audioElem.files[0]) return
			if (audioElem.files && audioElem.files[0]) {
				itemObj.audioElem = audioElem.files[0]
				const decodedAudio = await this.$imageHelper.convertAudioToBase64(audioElem.files[0])
				//console.log('Created')
				this.noteSections.map((item) => {
					if(parseInt(item.note_id) === parseInt(itemObj.note_id)){
						item.section_audio = decodedAudio
						//console.log(item.note_id)
					}
				})
			}
			//console.log(item)
		},
		saveAduioFile (itemObj) {
			const audio = itemObj.section_audio
			const audioFile = audio.indexOf('base64')
			if(audioFile !== -1){
				// const postData = {
				// 	note_id: itemObj.note_id,
				// 	audio: itemObj.section_audio
				// }
				const formData = new FormData()
				formData.append('audio_file', itemObj.audioElem, itemObj.audioElem.name)
				formData.append('note_id', itemObj.note_id)
				this.isLoading1 = true
				audioService.submitAudio(formData)
					.then((response) => {
						//console.log(response.data)
						this.isLoading1 = false
						if(parseInt(response.data.message_code) === 200) {
							this.noteSections.map((item) => {
								if(parseInt(item.note_id) === parseInt(itemObj.note_id)){
									item.section_audio = response.data.audioFile
									itemObj.audioElem = ''
								}
							})
							this.$swal({
								icon: "success",
								text: "Audio file uploaded successfully"
							})
						}else{
							this.$swal({
								icon: "error",
								text: "Error saving changes"
							})
						}
					})
					.catch((e) => {
						this.isLoading1 = false
						console.log(e)
						this.$swal({
							icon: "error",
							text: "Error saving changes"
						})
					})
			}else{
				this.$swal({
					icon: "error",
					text: "Add new audio file before saving"
				})
			}
		},
		deleteAudioFile (itemObj) {
			this.isLoading1 = true
			audioService.deleteAudio(itemObj.note_id)
				.then((response) => {
					this.isLoading1 = false
					if(parseInt(response.data.message_code) === 200){
						this.noteSections.map((item) => {
							if(parseInt(item.note_id) === parseInt(itemObj.note_id)){
								item.section_audio = response.data.audioFile
							}
						})
						this.$swal({
							icon: "success",
							text: "Audio file deleted successfully"
						})
					}else{
						this.$swal({
							icon: "error",
							text: "Error deleting file"
						})
					}
				})
				.catch((e) => {
					this.isLoading1 = false
					console.log(e)
					this.$swal({
						icon: "error",
						text: "Error deleting file"
					})
				})
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: var(--paul-color-lfx-new);
	height: auto;
	color: #ffffff;
	border-radius: 0px;
	margin-right: 10px;
	font-weight: 400 !important;
}
.card-loader{
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(225, 225, 225, 0.85);
}
</style>

import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class AudioService{
	apiUrl = baseUrl
	appUrl= storageService.setAppUrl()

	async loadCourse () {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-course`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async loadTopic (courseId) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-topics/${courseId}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async loadNote (courseId, topicId) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-note/${courseId}/${topicId}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async submitAudio (postData) {
		const apiToken = storageService.getItem().token
		return await axios.post(`${baseUrl}/submit-audio`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteAudio (noteId) {
		const apiToken = storageService.getItem().token
		return await axios.delete(`${baseUrl}/delete-audio/${noteId}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async loadComment (topicId, lastIndex) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-comment/${topicId}/${lastIndex}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteComment (id) {
		const apiToken = storageService.getItem().token
		return await axios.delete(`${baseUrl}/delete-comment/${id}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	// async fetchQuestions (topicId, lastIndex) {
	// 	const apiToken = storageService.getItem().token
	// 	return await axios.get(`${baseUrl}/load-questions/${topicId}/${lastIndex}`, {
	// 		headers: {
	// 			Authorization: `Bearer ${apiToken}`
	// 		}
	// 	})
	// }

	async fetchQuestions (lastIndex) {
		const apiToken = storageService.getItem().token
		return await axios.get(`${baseUrl}/load-questions/${lastIndex}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
}
const audioService = new AudioService()
export { audioService }